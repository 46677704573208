import { DisconnectOutlined, LinkOutlined } from "@/icons"
import cn from "classnames"
import type { IntegrationInstallationStatusProps } from "."
import styles from "./IntegrationInstallationStatus.module.scss"

export const IntegrationInstallationStatus = ({
  isInstalled,
  classes = {}
}: IntegrationInstallationStatusProps) => (
  <div
    className={cn(
      styles.container,
      !isInstalled && styles.disconnect,
      classes?.container
    )}
  >
    {isInstalled && <LinkOutlined />}
    {!isInstalled && <DisconnectOutlined />}
  </div>
)
