import cn from "classnames"
import { DeploymentTypeIconsGroup } from "../Icon"
import styles from "../Icon.module.scss"
import { DeploymentTypesProps } from "./DeploymentType.types"

export const DeploymentType = ({
  className,
  deploymentType,
  ...props
}: DeploymentTypesProps) => {
  const Icon =
    DeploymentTypeIconsGroup[deploymentType] || DeploymentTypeIconsGroup.unknown
  return (
    <Icon
      className={cn(styles[deploymentType], className)}
      data-tooltip-content={deploymentType}
      {...props}
    />
  )
}
