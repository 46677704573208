import cn from "classnames"
import { Link as LinkReactRouter } from "react-router-dom"

import styles from "./Link.module.scss"

import { LinkProps } from "."

export const Link = (props: LinkProps) => {
  const { to, children, type } = props
  const className = cn(props.className, { [styles.link]: type === "link" })

  if (to) {
    return (
      <LinkReactRouter to={to} className={className}>
        {children}
      </LinkReactRouter>
    )
  }

  const { href, target, onClick } = props

  return (
    <a href={href} target={target} className={className} onClick={onClick}>
      {children}
    </a>
  )
}
