import cn from "classnames"
import { Fragment } from "react"

import { getColorBySeverity, Severity } from "@/domain/vulnerability"

import styles from "./SeverityLetter.module.scss"

type SeverityLetterProps = {
  severity: Severity
  classes?: { container?: string }
  size?: "large" | "small"
  Wrapper?: React.FC | typeof Fragment
}

export const SeverityLetter = ({
  severity,
  size,
  classes = {},
  Wrapper = Fragment
}: SeverityLetterProps) => {
  const severityLowerCase = severity.toLowerCase()
  const backgroundColor = getColorBySeverity(severityLowerCase as Severity)
  const className = cn(
    styles.container,
    size === "large" && styles.largeSize,
    classes.container
  )
  const letter = severity[0]
  const tooltipContent = `${letter.toUpperCase()}${severityLowerCase.slice(1)}`
  const wrapperProps = Wrapper === Fragment ? {} : { title: tooltipContent }

  return (
    <Wrapper {...wrapperProps}>
      <span
        className={className}
        style={{ backgroundColor }}
        data-tooltip-content={tooltipContent}
      >
        {letter}
      </span>
    </Wrapper>
  )
}
