import cn from "classnames"

import { getLanguageIcon } from "@/icons"
import styles from "../Icon.module.scss"
import { PackageManagerProps } from "./PackageManager.types"

const packageManagerToLanguage: Record<PackageManager, Language> = {
  PACKAGE_MANAGER_GO_MODULES: "go",
  PACKAGE_MANAGER_NPM: "javascript",
  PACKAGE_MANAGER_PIP: "python",
  PACKAGE_MANAGER_POETRY: "python",
  PACKAGE_MANAGER_RUBYGEMS: "ruby",
  PACKAGE_MANAGER_MAVEN: "java",
  PACKAGE_MANAGER_COMPOSER: "php",
  PACKAGE_MANAGER_YARN: "javascript",
  PACKAGE_MANAGER_PNPM: "javascript",
  PACKAGE_MANAGER_SBT: "scala",
  PACKAGE_MANAGER_UNSPECIFIED: "unknown",
  PACKAGE_MANAGER_NUGET: "dotnet",
  PACKAGE_MANAGER_CARGO: "rust",
  PACKAGE_MANAGER_COCOAPODS: "swift",
  PACKAGE_MANAGER_BOWER: "javascript",
  PACKAGE_MANAGER_CONAN: "c",
  PACKAGE_MANAGER_HELM: "unknown"
}

const packageManagerIcon = (packageManger: PackageManager) => {
  const language = packageManagerToLanguage[packageManger]
  return getLanguageIcon(language)
}

export const PackageManagerIcon = ({
  className,
  packageManager = "PACKAGE_MANAGER_UNSPECIFIED",
  ...props
}: PackageManagerProps) => {
  const Icon = packageManagerIcon(packageManager)

  return (
    <Icon
      className={cn(styles.withBackground, className)}
      data-tooltip-content={packageManagerToLanguage[packageManager]}
      data-tooltip-translation="true"
      {...props}
    />
  )
}
