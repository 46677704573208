import styles from "./Drawer.module.scss"

import type { HandleResizableDrawerComponent } from "."

export const HandleResizableDrawer: HandleResizableDrawerComponent = ({
  onMouseDown
}) => (
  <div className={styles.handle}>
    <div onMouseDown={onMouseDown}>
      <svg width="17" height="63" viewBox="0 0 17 63" fill="none">
        <rect width="17" height="63" rx="8.5" fill="currentColor" />
        <path
          d="M8 12L8 52"
          stroke="white"
          strokeWidth="3"
          strokeLinecap="round"
        />
      </svg>
    </div>
  </div>
)
