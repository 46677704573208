import cn from "classnames"

import { Tooltip as TooltipAntd, TooltipProps } from "antd"

export const Tooltip = ({ rootClassName, ...props }: TooltipProps) => (
  <TooltipAntd
    rootClassName={cn(rootClassName, "root-class-tooltip-antd-ks")}
    {...props}
  />
)
