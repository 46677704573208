import type { ExploitMaturity } from "@/domain/package"
import type {
  IssuesSummary,
  Severity,
  VulnerabilitiesBySeverity,
  Vulnerability
} from "."

export const getColorBySeverity = (
  severity: Severity,
  type: "background" | "text" = "text"
) =>
  `var(--${severity.toLowerCase()}-vulnerabilities${type === "background" ? "-bg" : ""})`

export const getVulnerabilityColorBySeverity = getColorBySeverity

const checkRiskType = (riskId: string, type: string) => riskId.startsWith(type)

export const isCveRisk = (riskId: string) => checkRiskType(riskId, "CVE")
export const isCweRisk = (riskId: string) => checkRiskType(riskId, "CWE")
export const isMalRisk = (riskId: string) => checkRiskType(riskId, "MAL")
export const isGhsaRisk = (riskId: string) => checkRiskType(riskId, "GHSA")

export const getLinkByVulnerability = (
  vulnerability: Pick<Vulnerability, "cve">
) => {
  const riskId = vulnerability?.cve

  if (!riskId) {
    return undefined
  }

  if (isCveRisk(riskId)) return `https://nvd.nist.gov/vuln/detail/${riskId}`

  if (isGhsaRisk(riskId)) return `https://github.com/advisories/${riskId}`

  if (isMalRisk(riskId)) return `https://osv.dev/vulnerability/${riskId}`

  if (isCweRisk(riskId))
    return `https://cwe.mitre.org/data/definitions/${riskId.substring(4)}.html`
}

export const getAllSeverities = () => allSeverities

export const mapSeverities = (fn: (key: Severity, index: number) => any) =>
  getAllSeverities().map(fn)

export const getWorstSeverity = (
  vulnerabilitiesBySeverityLevel: VulnerabilitiesBySeverity
) => {
  return (
    getAllSeverities().find(
      (severity) => vulnerabilitiesBySeverityLevel[severity]?.length
    ) || "unknown"
  )
}

export const getIssuesCountMapOrderedSeverity = (
  issuesSummary: IssuesSummary | undefined
) => {
  if (!issuesSummary) return []

  return mapSeverities((severity) => {
    return [severity, issuesSummary?.[severity] || 0]
  }).filter(([_, count]) => count > 0)
}

export type SeverityMaturity = Record<Severity, ExploitMaturity | undefined>

export const findHighestExploitMaturities = (
  vulnerabilities: Vulnerability[]
): SeverityMaturity => {
  const highestMaturities: SeverityMaturity = {
    critical: undefined,
    high: undefined,
    medium: undefined,
    low: undefined,
    negligible: undefined,
    unknown: undefined
  }

  for (const vulnerability of vulnerabilities) {
    const currentSeverity = vulnerability.severity
    const currentMaturity = vulnerability.exploitMaturity

    if (currentMaturity === "IN_THE_WILD") {
      highestMaturities[currentSeverity] = "IN_THE_WILD"
      break
    } else if (
      currentMaturity === "POC" &&
      !highestMaturities[currentSeverity]
    ) {
      highestMaturities[currentSeverity] = "POC"
    }
  }

  return highestMaturities
}

export const allSeverities = [
  "critical",
  "high",
  "medium",
  "low",
  "negligible",
  "unknown"
] as const

export const getCountByAllSeverity = (
  vulnerabilities: VulnerabilitiesBySeverity
) => {
  return allSeverities.reduce(
    (acc, severity) => {
      acc[severity] = vulnerabilities[severity]?.length || 0

      return acc
    },
    {} as Record<Severity, number>
  )
}
