import styles from "./CellTable.module.scss"

export const CellTable = (
  value: string | { text: string; tooltip?: string },
  record?: { noTooltip?: boolean } & any
) => {
  const useValue = typeof value !== "object"
  const text = useValue ? value : value.text
  const tooltip = useValue ? value : value.tooltip

  return (
    <span
      className={styles.wrap}
      data-tooltip-content={record?.noTooltip ? undefined : tooltip}
    >
      {text}
    </span>
  )
}
