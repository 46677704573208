import { BitbucketDark, GitHub, GitlabDark, IconProps, OnPrem } from ".."

import type { SourceControlIconProps } from "./SourceControl.types"

const repositoryIconMapping: Record<
  SourceControl.Vendor,
  (props: IconProps) => JSX.Element
> = {
  GitHub: GitHub,
  Bitbucket: BitbucketDark,
  OnPrem: OnPrem,
  GitLab: GitlabDark
}

const sourceControlTooltipMapping: Record<SourceControl.Vendor, string> = {
  GitHub: "GitHub",
  Bitbucket: "Bitbucket",
  OnPrem: "Local Scan",
  GitLab: "GitLab"
}

export const SourceControlIcon = ({
  sourceControl,
  className,
  padding,
  ...props
}: SourceControlIconProps) => {
  const Icon = repositoryIconMapping[sourceControl]

  if (!Icon) return null

  return (
    <Icon
      data-tooltip-content={sourceControlTooltipMapping[sourceControl]}
      className={className}
      {...props}
    />
  )
}
