import cn from "classnames"

import { FrownOutlined, HasFix, MehOutlined, SmileOutlined } from "@/icons"

import styles from "./FixabilityIcon.module.scss"

import type { Fixability } from "@/api/useIssues.types"

export const FixabilityIcon: React.FC<{ fixability: Fixability }> = ({
  fixability
}) => {
  const value = fixability === "has_fix" ? "hasFix" : fixability
  const className = cn(styles.common, styles[value])

  if (fixability === "full") return <SmileOutlined className={className} />
  else if (fixability === "partial")
    return <MehOutlined className={className} />
  else if (fixability === "has_fix") return <HasFix className={className} />
  else if (fixability === "not_fixable")
    return <FrownOutlined className={className} />

  return null
}
