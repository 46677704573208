import { Drawer as DrawerAntd } from "antd"
import cn from "classnames"

import styles from "./Drawer.module.scss"

import type { DrawerComponentType } from "."

export const DrawerComponent: DrawerComponentType = (props) => {
  const { className, children, defaultView, resizableView, ...drawerProps } =
    props

  return (
    <DrawerAntd
      rootClassName={cn(resizableView && styles.resizable)}
      className={cn(className, !defaultView && styles.container)}
      {...drawerProps}
    >
      {children}
    </DrawerAntd>
  )
}
