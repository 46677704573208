import DarkThemeLogo from "@/assets/images/logo_dark.png"
import LightThemeLogo from "@/assets/images/logo_light.png"

import type { LogoImageProps } from "."

export const LogoImage = ({
  dark,
  testId = "LogoImage",
  ...props
}: LogoImageProps) => (
  <img
    alt="Logo"
    src={dark ? DarkThemeLogo : LightThemeLogo}
    data-testid={testId}
    {...props}
  />
)
