import cn from "classnames"

import styles from "./InkTag.module.scss"

import type { InkTagProps } from "."

export const InkTag = ({ color, dot }: InkTagProps) => {
  return (
    <div
      style={{ backgroundColor: color }}
      className={cn(styles.tag, dot && styles.dot)}
    />
  )
}
