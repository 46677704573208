import { DrawerComponent } from "./DrawerComponent"
import { ResizableDrawer } from "./ResizableDrawer"

import type { DrawerComponentProps } from "."

export const Drawer = (props: DrawerComponentProps) => {
  if (props.resizable) {
    return <ResizableDrawer {...props} />
  }

  return <DrawerComponent {...props} />
}
