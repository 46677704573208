type Score = number | undefined
type KodemScoreSeverity = "critical" | "high" | "medium" | "low" | "unknown"

export const getScore = (score: Score, fractionDigits = 1) => {
  return score?.toFixed(fractionDigits) ?? "N/A"
}

export const getPostureScoreSeverity = (score: number) => {
  if (!score || score < 70) {
    return {
      result: "unsatisfactory"
    }
  }
  if (score < 80) {
    return {
      result: "fair"
    }
  }
  if (score < 85) {
    return {
      result: "solid"
    }
  }
  if (score < 90) {
    return {
      result: "good"
    }
  }

  if (score < 95) {
    return {
      result: "excellent"
    }
  }

  return {
    result: "exceptional"
  }
}

const KODEM_SCORE_THRESHOLDS = [9, 7, 4, 0]
const KODEM_SCORE_SEVERITIES: KodemScoreSeverity[] = [
  "critical",
  "high",
  "medium",
  "low",
  "unknown"
]

export const getKodemScoreSeverity = (score: Score) => {
  if (!score || score < 0) return "unknown"

  const index = KODEM_SCORE_THRESHOLDS.findIndex(
    (threshold) => score >= threshold
  )

  return KODEM_SCORE_SEVERITIES[index]
}

export const getKodemScoreColor = (score: Score) => {
  return `var(--${getKodemScoreSeverity(score)}-ks)`
}
